/*****************************************/
/* Start your style declarations here    */
/*****************************************/
/* 
 You can use this CSS file as a guide in building out
 your HubSpot website. It contains the major elements needed
 to create your own custom style. 
*/

/* Your website background color (background:#fff;) */

html, body {
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  font-family: $font-normal;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  color: #100017;
  color: $black;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $purple;
  transition: all ease-in-out 0.2s;
  text-decoration: none;
}

a:hover, a:focus {
  color: $black;
}

img {
  max-width: 100%;
  height: auto;
}

/* ------ Typography ------ */
/* Basic text */
p {
}

small {
}

strong {
}

em {
}

cite {
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
}

h1 {
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-family: $font-condensed;
  line-height: 0.83;
  letter-spacing: 3px;
}

h2 {
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-family: $font-condensed;
  line-height: 1.2;
  letter-spacing: 3px;
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

p {
  margin: 0 0 ($vr * 2) 0;
}

/* Lists */

ul:not(.stripped):not(.inputs-list):not(.check) {
  list-style: none;
  padding-left: ($vr * 2);

  li:not(.hs-menu-item) {
    position: relative;
    margin-bottom: $vr;

    &:before {
      content: '*';
      position: absolute;
      left: -1.8rem;
      border-right: 1px solid $gray;
      height: 85%;
      font-size: 1.7em;
      font-weight: 100;
      line-height: 1;
      padding-right: 7px;
      top: 5px;
    }
  }
}

.hs-tools-menu {
  ul {
    li {
      &:before {
        display: none;
      }
    }
  }
}

ol:not(.stripped) {
  list-style: none;
  counter-reset: numbered_list;
  padding-left: ($vr * 2);

  li {
    counter-increment: numbered_list;
    position: relative;
    margin-bottom: $vr;

    &:before {
      content: counter(numbered_list, decimal-leading-zero);
      position: absolute;
      left: -1.8rem;
      border-right: 1px solid $gray;
      height: 85%;
      line-height: 1;
      padding-right: 5px;
      top: 5px;
      font-family: $font-condensed;
      font-weight: 300;
      font-size: 18px;
    }
  }
}

ul.check {
  list-style: none;
  padding-left: 0;

  li {
    position: relative;
    border-bottom: 1px solid #9334bb;
    padding: ($vr - ((1.58 * 19) - 19)) ($vr * 2) ($vr - ((1.58 * 19) - 19)) 0;

    &:before {
      content: '';
      position: absolute;
      right: 8px;
      top: 8px;
      width: 8px;
      height: 18px;
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      transform: rotate(35deg);
    }
  }
}

.hs_cos_wrapper_type_rich_text {
  ul, ol {
    margin-bottom: 1.666666667em;
  }

  .hs-cta-wrapper {
    display: block;
    margin-top: 2.25em;
  }
}

ul, ol {
}

ul ul, ul ol, ol ol, ol ul {
}

li {
}

ul.unstyled, ol.unstyled {
}

ul.inline, ol.inline {
}

ul.inline > li, ol.inline > li {
}

dl {
}

dt, dd {
}

dt {
}

dd {
}

/* Quotes */
blockquote {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-family: $font-condensed;
  line-height: 1.5;
  letter-spacing: 1.3px;
  color: $dark-gray;
  margin: 0 20px 20px 20px;
}

.post-body--postpage blockquote {
  @media (min-width: 768px) {
    font-size: 26px;
    float: right;
    width: 300px;
    margin-right: 0;
  }

  @media (min-width: 1185px) {
    font-size: 30px;
    width: 360px;
    margin-right: -70px;
  }
}

blockquote p {
  margin-bottom: 0;
}

blockquote small {
}

blockquote small:before {
}

q:before, q:after, blockquote:before, blockquote:after {
}

/* Address */
address {
}

/* Forms */

input, button, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

input, button {
  transition: all ease-in-out 0.2s;
}

input, select, textarea {
  font-weight: 400;
  letter-spacing: 0.3px;
  font-size: 16px;
  line-height: 1.4375;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding-left: ($vr * 4);
  padding-right: $vr;
  width: 100%;
  border: 1px solid $gray;
  padding: $vr !important;
}

input[type=submit] {
  width: auto;
}

input[type=button],
input[type=submit],
button,
.button {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-family: $font-condensed;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: $purple;
  border: none;

  &:hover, &:focus {
    cursor: pointer;
    background-color: #a903ee;
    color: #fff;
  }
}

html:not(.hs-inline-edit) {
  input[type=button],
  input[type=submit],
  button,
  .button {
    padding: $vr ($vr * 4) !important;
  }
}

//Hide default radio
input[type=radio], input[type=checkbox] {
  display: none;
  //The text next to the radio
  & + span {
    position: relative;
    padding-left: 1.2em;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }
    //The circle that replaces the default radio
    &:before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid $dark-gray;
      left: .0625em;
      top: .1875em;
      position: absolute;
    }
  }
  //If the radio is selected
  &:checked + span {

    &:before {
      background-color: $purple;
      border-color: $purple;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: 7px;
      left: 5px;
    }
  }
}

input[type=checkbox] {
  //The text next to the radio
  & + span {
    //The circle that replaces the default radio
    &:before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      background-color: #fff;
      border: 1px solid $dark-gray;
      left: 0;
      top: 2px;
      position: absolute;
      border-radius: 0;
    }
  }
  //If the radio is selected
  &:checked + span {

    &:before {
      background-color: $purple;
      border-color: $purple;
    }

    &:after {
      border-radius: 0;
      content: '';
      position: absolute;
      left: 6px;
      top: 4px;
      width: 3px;
      height: 8px;
      background: transparent;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(35deg);
    }
  }
}

.hs-form-field {
  margin-bottom: 1.333333333em;
}
.hs-form-required {
  display: none;
}

.hs-error-msgs {
  margin-top: -12px !important;
  list-style: none;
  padding: 0;
  line-height: 1.2;

  label {
    color: #f00;
    font-size: 16px;
  }
}

.gap-blogsubscribe .hs-error-msgs {
  margin-top: 0 !important;
}

//form .input {
//  margin-bottom: $vr;
//}

body .hs-form fieldset {
  max-width: 100%;

  textarea {
    min-height: 150px;
  }

  .hs-form-field {
    line-height: 1;
  }

  .hs-input {
    width: 100% !important;
  }

  &.form-columns-1, &.form-columns-2, .form-columns-3 {
    .input {
      margin-right: 0;
    }
  }

  @media (min-width: 401px) {
    &.form-columns-1 {

      .input {
        margin-right: 0;
      }
    }

    &.form-columns-2 {

      .input {
        margin-right: 0;
      }

      .hs-form-field {
        &:first-child {
          .input {
            margin-right: 10px;
          }
        }

        &:last-child {
          .input {
            margin-left: 10px;
          }
        }
      }
    }

    &.form-columns-3 {

      .input {
        margin-right: 5px;
        margin-left: 5px;
      }

      .hs-form-field {
        &:first-child {
          .input {
            margin-left: 0;
            margin-right: 15px;
          }
        }

        &:last-child {
          .input {
            margin-left: 15px;
            margin-right: 0;
          }
        }
      }
    }
  }
}

#captcha_wrapper {
  margin-bottom: $vr;
}

/* ------ Structure ------ */
/* 
 The outer wrappers of your website, if you wanted to set your website width
 to a fixed size you could set a width or max-width of 960px for example. 
*/
.header-container-wrapper, .body-container-wrapper, .footer-container-wrapper {
}

.header-container-wrapper {
}

.body-container-wrapper {
}

.footer-container-wrapper {
}

/* The inner wrappers of your website */
.header-container, .body-container, .footer-container {
}

.header-container {
}

.body-container {
}

.footer-container {
}

/* ------ Grid ------ */
/* 
 By default HubSpot's grid system utilizes 12 columns, making for a 940px wide container 
 without responsive features enabled. With the responsive CSS file added, the grid adapts 
 to be 724px and 1170px wide depending on your viewport. Below 767px viewports, the columns 
 become fluid and stack vertically. 
 
 You can also use spans to setup your different colored sidebar or container columns like 
 this: span8 {background:#fff;} span4 {background:#eee;}. The column spans will always add 
 up to 12 and will be setup for you automatically when you use Template Builder to adjust 
 the visual layout.
*/
.span12 {
}

.span11 {
}

.span10 {
}

.span9 {
}

.span8 {
}

.span7 {
}

.span6 {
}

.span5 {
}

.span4 {
}

.span3 {
}

.span2 {
}

.span1 {
}

/* ------ Header ------ */
.header-container-wrapper {
}

.header-container {
}

/* Logo styling */
.header-container .hs_cos_wrapper_type_logo {
}

.header-container .hs_cos_wrapper_type_logo a {
}

.header-container .hs_cos_wrapper_type_logo a img {
}

/* ------ Menu ------ */
/* 
 Change the alignment of your menu easily by using the text-align or float CSS properties. 
 For a full width centered menu, you could simply add text-align:center; below.
 */
.header-container .hs-menu-wrapper.hs-menu-flow-horizontal {
}

.hs-menu-wrapper {
}

/* Primary menu link text color */
.hs-menu-wrapper a {
}

.hs-menu-wrapper a:hover {
}

/* Drop down background color */
.hs-menu-wrapper.hs-menu-flow-horizontal ul {
}

/* Menu link font family and basic padding including drop down links */
.hs-menu-wrapper.hs-menu-flow-horizontal > ul li a {
}

/* ------ Content ------ */
.body-container-wrapper {
}

.body-container {
}

/* ------ Blog ------ */
/* Your basic blog wrapper */
.blog-listing-wrapper {
}

/* Use this to set up different styling for your blog posts */
.blog-listing-wrapper .post-listing {
  .post-item {

  }
  .post-body {
    margin-bottom: 30px;
    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.post-body--postpage {
  @media (min-width: 768px) {
    max-width: 700px;
  }
}

/* Blog comments and blog comment form */
.comment {
}

#comments-listing {
  .comment.depth-0 {
    border-top: none;
  }
}

.comment-from {
}

.comment-body {
}

.comment-date {
}

#blog-comment-form div.hs-form-field {
}

#blog-comment-form div.hs-form-field label {
}

#blog-comment-form div.hs-form-field .hs-field-desc {
}

#blog-comment-form div.hs-form-field input[type="text"],
#blog-comment-form div.hs-form-field input[type="email"],
#blog-comment-form div.hs-form-field textarea {
}

#blog-comment-form div.hs-form-field select {
}

#blog-comment-form .hs-button {
}

#blog-comment-form textarea,
#blog-comment-form input[type="text"],
#blog-comment-form input[type="password"],
#blog-comment-form input[type="datetime"],
#blog-comment-form input[type="datetime-local"],
#blog-comment-form input[type="date"],
#blog-comment-form input[type="month"],
#blog-comment-form input[type="time"],
#blog-comment-form input[type="week"],
#blog-comment-form input[type="number"],
#blog-comment-form input[type="email"],
#blog-comment-form input[type="url"],
#blog-comment-form input[type="search"],
#blog-comment-form input[type="tel"],
#blog-comment-form input[type="color"],
#blog-comment-form .uneditable-input {
}

#blog-comment-form form div.hs-form-field ul li {
}

.hs_submit {
}

/* Pagination */
.post-header a {
}

.post-header p {
}

.blog-pagination {
  margin-bottom: ($vr * 5);
}

/* Subscription frequency form */
.hs_blog_default_blog_subscription {
}

.hs_blog_default_blog_subscription ul {
}

/* ------ Forms ------ */
/* This is the main form wrapper */
.hs-form {
}

.hs-input {
}

.hs-input:hover {
}

.hs-input:focus {
}

.field label {
}

/* ------ Buttons ------ */
.hs-button {
}

.hs-button:hover {
}

.hs-button:focus {
}

.hs-button:active {
}

.hs-button:hover, .hs-button:focus, .hs-button:active {
}

/* ------ Footer ------ */
/* The main footer wrapper will default to fill 100% of the browser viewport */
.footer-container-wrapper {
}

/* Your basic footer container */
.footer-container {
}

/* ------ Misc ------ */
/* Any overflow styles can go in this space */

/* ------ Mobile ------ */
/* Large desktop */
@media (min-width: 1200px) {
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
}

/* Landscape phones and down */
@media (max-width: 480px) {
}

.post-item {
  margin-bottom: ($vr * 5);
}

.more-link {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-family: $font-condensed;
  letter-spacing: 1.6px;
  text-transform: uppercase;

  &:after {
    content: ' \2192';
  }
}

.blog-pagination {

  a {
    margin-right: -5px;
    display: inline-block;
  }

  .previous-posts-link {
    background-color: $black;

    &:hover, &:focus {
      background-color: lighten($black, 10%);
    }
  }

  .all-posts-link {
  }

  .next-posts-link {
    background-color: $black;

    &:hover, &:focus {
      background-color: lighten($black, 10%);
    }
  }
}

.container-fluid .row-fluid .page-center {
  margin: 0 auto !important;
  max-width: 1200px + (2*$vr);
  float: none;
  padding: 0 2*$vr;
}

.gap-sticky {
  width: 100% !important;
  float: none !important;
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: $purple;
  line-height: normal;
  padding: 24px 0;
  transition: ease-in-out 0.3s;

  @media (min-width: 768px) {
    padding: ($vr * 2) 0;

    &.scrolled {
      padding: 24px 0;
    }
  }

  &--dark {
    background-color: $black;
  }
}

.hs-inline-edit .gap-sticky {
  position: static;
}

.gap-header {
  background-color: $purple;
  color: #fff;
  padding-top: ($vr * 3);

  &--dark {
    background-color: $black;
  }
}

.gap-postlistingheadline {
  margin: ($vr * 2) 0;
}

.gap-postlistingheadline a {
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-family: $font-condensed;
  line-height: 1.2;
  color: $black;
  text-decoration: none;
  letter-spacing: normal;

  &:hover, &:focus {
    color: $purple;
  }
}

.gap-footer {
  background-color: $black;
  color: $gray;
  font-size: 18px;
  line-height: 1.66666667;
  font-weight: normal;
  font-style: normal;
  font-family: $font-condensed;
  padding: 120px 0;

  h3 {
    /*
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1.67;
    letter-spacing: 2px;*/
    margin: 0;
    font-family: $font-condensed;
    padding-bottom: 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.66666667;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  a {
    color: rgba(255, 255, 255, 0.7);

    &:focus, &:hover {
      color: $purple;
    }
  }

  .gap-footerlogo {
    text-align: center;
    margin-bottom: 60px;

    img {
      width: 176px !important;
      height: auto;
    }
  }

  .gap-contactus {
    margin-bottom: ($vr * 2);

    .normal {
      font-weight: 400 !important;
      color: rgba(255, 255, 255, 0.7);
    }

    .link-map {
      font-weight: 700;
      text-decoration: underline;
      color: #d3d3d3;

      &:hover, &:focus {
        color: $purple;
      }
    }

    p {
      font-weight: 700;
      margin: 0;
      padding: 6px 0;
      letter-spacing: 0.5px;

      &:first-child {
        padding-top: 0;
      }
    }

    a {
      font-weight: 400;
    }
  }
}

.gap-logo {
}

.gap-logo img {
  margin-top: 3px !important;
  height: 20px !important;
  width: auto !important;
  max-width: inherit;

  @media (min-width: 768px) {
    margin-top: 6px !important;
  }
}

.row-fluid .gap-menu {

  ul {
    margin-top: 1px;
    margin-bottom: 0;
    @media (min-width: 768px) {
      text-align: right;
    }
  }

  @media (max-width: 767px) {
    position: fixed;
    background: rgba(121, 2, 170, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none !important;

    div, span {
      width: 100%;
      height: 100%;
      display: block;
    }

    .hs-menu-wrapper {
      vertical-align: middle;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 0;

      a {
        font-size: 28px;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-weight: 700;
        max-width: none;

        &:hover, &:focus {
          box-shadow: none;
        }
      }
    }

    ul {
      float: none;
    }

    li {
      float: none !important;
      margin-bottom: 25px;
    }
  }
}

.gap-mobilemenu-wrapper {
  min-height: 0 !important;

  @media (min-width: 768px) {
    display: none !important;
  }
}

#mobile-menu-trigger {
  display: block;
  z-index: 9999;
  position: fixed;
  top: 15px;
  right: 20px;
  width: 44px;
  height: 44px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: 0.3s;

  @media (min-width: 768px) {
    top: 23px;
  }

  div {
    width: 30px;
    height: 2px;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    background-color: #ffffff;
    cursor: pointer;
    transition: all 0.15s ease-in-out 0.1s;
  }

  .line-one {
    top: 11px;
  }

  .line-two {
    top: 20px;
  }

  .line-three {
    top: 20px;
    opacity: 0;
  }

  .line-four {
    top: 29px;
  }
}

body.menu-open {
  overflow: hidden;

  .row-fluid .gap-menu {
    display: block !important;
    width: 100%;

    ul {
      width: 80%;
      margin: 0 auto;
      padding-left: 0 !important;
    }
    li {
      margin-bottom: 15px;

      @media (min-width: 480px) {
        margin-bottom: 20px;
      }
    }
  }

  #mobile-menu-trigger {
    .line-one {
      top: 20px;
      opacity: 0;
    }

    .line-two {
      transform: rotate(45deg);
    }

    .line-three {
      transform: rotate(-45deg);
      opacity: 1;
    }

    .line-four {
      top: 20px;
      opacity: 0;
    }
  }
}

.gap-menu ul {
  float: right;
}

.hs-menu-item {
  line-height: 0;
}

.gap-menu {

  li.active a {
    box-shadow: 0 1px 0 #ffffff;

    @media (max-width: 767px) {
      color: rgba(255, 255, 255, 0.5);
      box-shadow: none;
    }
  }

  li {
    display: block;
    @media (min-width: 768px) {
      display: inline-block;
    }
    float: none !important;
    margin-left: -1px;

    &:last-child {
      margin-left: 4px;
    }
  }

  a {
    padding: 7.5px 7.5px !important;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
    font-family: $font-condensed, Helvetica, Arial, sans-serif;
    text-transform: uppercase;

    @media (min-width: 960px) {
      padding: 7.5px 15px !important;
    }


    &:hover, &:focus {
      text-decoration: none;
    }

    @media (min-width: 768px) {
      &:hover, &:focus {
        color: #fff;
        box-shadow: 0 1px 0 #ffffff;
      }
    }
  }

  li:last-child a {
    @media (min-width: 768px) {
      background-color: $purple;
      box-shadow: inset 0 0 0 1px #fff;
      color: rgba(255, 255, 255, 0.5);

      &:hover, &:focus {
        box-shadow: inset 0 0 20px 0px transparent;
        background-color: #a903ee;
        color: #fff;
      }
    }
  }
}

.gap-sticky--dark .gap-menu {
  @media (min-width: 768px) {
    li:last-child a {
      background-color: $purple;
      box-shadow: inset 0 0 0 1px $black;
      color: #fff;
      box-shadow: none;

      &:hover, &:focus {
        box-shadow: none;
        background-color: #a903ee;
      }
    }
  }
}

.gap-blogheading {
  text-transform: uppercase;
  font-size: 44px;
  line-height: 1.13888889;
  font-weight: 800;
  letter-spacing: 2px;
  margin-top: ($vr * 10) !important;
  margin-bottom: ($vr * 2);

  @media (min-width: 768px) {
    font-size: 72px;
  }

  &--topic {
    margin-bottom: ($vr * 11);
  }
}

.gap-blogsubheading {
  font-family: $font-condensed, Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 1.25;
  font-weight: 100;
  text-transform: none;
  letter-spacing: 1.5px;
  margin-bottom: ($vr * 11);

  @media (min-width: 768px) {
    font-size: 28px;
  }
}

.blog-section {
  margin-top: ($vr * 4);
  margin-bottom: ($vr * 4);
}

.gap-postheader {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-family: $font-condensed;
  line-height: 1.6667;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: $dark-gray;
  border-bottom: 1px solid $border-gray;
  display: flex;
  flex-direction: column;

  > * {
    flex-grow: 0;
    padding: .42em 0;
  }

  > *:first-child {
    padding-left: 0;
  }

  > *:not(:last-child) {
    border-bottom: 1px solid $border-gray;
  }

  > *:last-child {
    flex-grow: 1;
  }

  a {
    color: $dark-gray;

    &:hover, &:focus {
      color: $purple;
    }
  }

  @media (min-width: 500px) {
    flex-direction: row;

    > * {
      flex-grow: 0;
      padding-left: ($vr);
      padding-right:($vr);
    }

    > *:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid $border-gray;
    }
  }
}

.hs-featured-image {
  float: none;
  max-width: 100%;
  margin: 0 0 ($vr * 2) 0;
}

.blog-sidebar {
  padding: 0 ($vr);

  @media (max-width: 767px) {
    margin-top: ($vr * 2);
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  @media (min-width: 768px) {
    padding: 0 ($vr * 2);
    //margin-left: 0 !important;
    //margin-right: 0 !important;
    border-left: 1px solid $border-gray;
    border-right: 1px solid $border-gray;
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: $dark-gray;
    margin-top: 0;
    margin-bottom: ($vr * 2);
  }

  a {
    &:hover, &:focus {
      color: $purple !important;
    }
  }

  > *:last-child {
    .gap-sidebarwidgetwrapper {
      border-bottom: none;
    }
  }
}

.gap-blogsearch {
  width: auto !important;
  float: none !important;
  position: relative;
  margin: 0 (-$vr) !important;
  padding: 0 ($vr);
  border-bottom: 1px solid $border-gray;

  @media (max-width: 767px) {
    border-top: 1px solid $border-gray;
  }

  @media (min-width: 768px) {
    margin: 0 (-$vr * 2) !important;
    padding: 0 ($vr * 2);
  }

  label {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .input {
    margin-bottom: 0;
  }

  input {
    border: none;
    color: $dark-gray;
    padding-left: 45px !important;
    position: relative;
    height: ($vr * 4);
  }

  a {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(http://cdn2.hubspot.net/hubfs/2380340/Global_assets/search2x.png);
    background-repeat: no-repeat;
    background-size: 22px 23px;
    background-position: center;

    &:hover {
      cursor: pointer;
    }
  }
}

.gap-blogsubscribe {

  .hs-form-field > label {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  input[type=submit] {
    width: 100%;
    padding: ($vr) 0 !important;
  }

  ul {
    list-style: none;
    margin: ($vr * 2) 0;
    padding: 0;
  }

  .inputs-list.multi-container {
    display: flex;
    flex-direction: column;

    @media (min-width: 1090px) {
      flex-direction: row;
    }

    > *:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.gap-recentpost {
  margin-bottom: $vr;

  .gap-recentpostname {
    color: $black;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1.44;
    letter-spacing: 0.4px;
  }

  .gap-recentpostauthor {
    color: $dark-gray;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1.44;
  }
}

.gap-topics {
  ul {
    padding: 0 !important;
    margin: 0;
    list-style: none;
  }

  li {
    overflow: hidden;
    border-bottom: 1px solid $gray;
    margin-bottom: 0 !important;

    &:first-child a {
      margin-top: 0;
    }
  }

  a {
    display: block;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1;
    letter-spacing: 0.4px;
    margin: $vr 0;
    color: $black;
  }

  .filter-link-count {
    float: right;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1.2;
    letter-spacing: 0.4px;
    color: $dark-gray;
  }
}

.gap-sidebarwidgetwrapper {
  width: auto !important;
  float: none !important;
  position: relative;
  border-bottom: 1px solid $border-gray;
  margin: 0 (-$vr) !important;
  padding: ($vr * 3) ($vr);

  &--last {
    border-bottom: none;
  }

  @media (min-width: 768px) {
    margin: 0 (-$vr * 2) !important;
    padding: ($vr * 3) ($vr * 2);
  }
}

.gap-authorlist {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: $vr;
  }

  .gap-authorinfo {
    display: flex;
    align-items: center;

    img {
      width: 45px;
      height: auto;
      margin-right: $vr;
    }

    a {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-family: $font-condensed;
      line-height: 1.2;
      letter-spacing: 0.4px;
      color: $black;
    }
  }

  .gap-authorpostcount {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1.2;
    letter-spacing: 0.4px;
    color: $dark-gray;
    display: flex;
    align-items: center;
  }
}

.gap-bodytop-wrapper {
  position: relative;

  &:before {
    content: '';
    height: 1px;
    width: 100vw;
    background: $border-gray;
    position: absolute;
    display: block;
    bottom: 0;
    right: 100%;
  }
}

.gap-bodytop {
  border-bottom: 1px solid $border-gray;
  padding: $vr 0;
  margin-right: -$vr;
  position: relative;

  &--postbody {
    height: ($vr * 8);
    padding: 0;
    margin-left: -$vr;

    @media (min-width: 450px) {
      margin-left: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: stretch;
      height: ($vr * 4);
    }

    .author-wrapper {
      display: flex;
      flex-grow: 1;
      align-items: center;
      height: ($vr * 4);
      padding: 0 $vr;

      @media (min-width: 450px) {
        flex-grow: 0;
        height: auto;
        padding: 0;
      }
    }

    .author-info {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      color: $dark-gray;
      font-family: $font-condensed;
      margin-left: $vr;

      span {
        display: inline-block;
        margin: 0 10px;
      }
    }

    .social-share-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      flex-grow: 1;
      color: $dark-gray;
      font-weight: 400;
      letter-spacing: 0.3px;
      font-size: 16px;
      line-height: 1;
      height: ($vr * 4);
      padding: 0 $vr;
      border-top: 1px solid $border-gray;

      @media (min-width: 450px) {
        height: auto;
        padding: 0 ($vr * 2);
        flex-grow: 0;
        border-top: none;
        border-left: 1px solid $border-gray;
      }

      a {
        margin-left: 20px;
        flex-shrink: 0;
      }
    }
  }

  @media (min-width: 768px) {
    //margin-right: 0;
    margin-right: (-$vr * 2);
  }

  .gap-bodytop-heading {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 1px;
    font-family: $font-condensed;
    line-height: 1;

    @media (min-width: 550px) {
      font-size: 25px;
    }

    > a {
      color: $dark-gray;
    }

    img {
      position: relative;
      top: -5px;
      width: 18px;
      height: 18px;
      margin-left: 3px;

      @media (min-width: 550px) {
        top: -3px;
        width: 25px;
        height: 25px;
      }

      &:hover {
        opacity: .75;
      }
    }
  }
}

.gap-author-headerblock {
  margin: 100px 0 80px 0;
  max-width: 750px;

  .gap-postheader {
    color: #fff;
  }

  .gap-author-headerblock-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;

    @media (min-width: 650px) {
      flex-direction: row;
    }

    img {
      flex-grow: 0;
      flex-shrink: 0;
      width: 100px;
      height: 100px;

      @media (min-width: 650px) {
        width: 164px;
        height: 164px;
        margin-left: 10px;
      }
    }

    h1 {
      text-transform: uppercase;
    }

    h1, img {
      margin-top: ($vr * 2);
      line-height: 1;
    }
  }

  .gap-author-headerblock-social {
    .social {
      display: inline-block;
      margin-right: 20px;
      line-height: 1;
    }
  }
}

.gap-popularposts {
  background-color: $light-gray;
  padding: ($vr * 5) 0;

  .gap-poppostswrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .gap-poppost {
      background-color: $black;
      color: #fff;
      display: flex;
      flex-direction: column;
      margin-bottom: ($vr * 2);

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      &:hover, &:focus {
        .gap-poppost-image {
          opacity: 0.6;
        }
      }
    }

    .gap-poppost-image {
      height: 200px;
      background-size: cover;
      background-position: center;
      transition: all ease-in-out 0.2s;
    }

    .gap-poppost-text {
      text-align: center;
      padding: ($vr * 2);
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-family: $font-condensed;
      line-height: 1.67;
      letter-spacing: 0.5px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (min-width: 768px) {
      flex-direction: row;

      .gap-poppost {
        width: 33.3333333%;

        &:not(:last-child) {
          margin-right: ($vr * 2);
        }
      }
    }
  }
}

.gap-blogpost-headerblock {
  margin: 100px 0 80px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .gap-blogpost-introcontent {

    .gap-postheader {
      color: #675e6b;
      border-bottom-color: #675e6b;

      > *:not(:last-child) {
        border-right-color: #675e6b;
        border-bottom-color: #675e6b;
      }

      a {
        color: #675e6b;

        &:hover, &:focus {
          color: #fff;
        }
      }
    }

    @media (min-width: 768px) {
      width: 55%;
      margin-right: 80px;

      &.wide {
        width: 75%;
        margin-right: 0;
      }
    }
  }

  .gap-postheader-featuredimage {
    flex-grow: 1;
    margin-top: ($vr * 2);
    background-size: cover;
    background-position: center;
    height: 200px;

    @media (min-width: 768px) {
      height: auto;
      min-height: 280px;
    }
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1.25;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

.gap-blogpost-bottom-share {
  height: ($vr * 4);
  display: flex;
  align-items: center;

  &-wrapper {
    border-top: 1px solid $border-gray;
    border-bottom: 1px solid $border-gray;
  }

  .social-share-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: $dark-gray;
    font-weight: 400;
    letter-spacing: 0.3px;
    font-size: 16px;
    line-height: 1;
    height: ($vr * 4);

    @media (min-width: 450px) {
      height: auto;
      flex-grow: 0;
    }

    a {
      margin-left: 15px;
      flex-shrink: 0;

      @media (min-width: 768px) {
        margin-left: 20px;
      }
    }
  }
}

.gap-bottom-author {
  padding: ($vr * 4) 0;
  font-size: 16px;

  .span2 img {
    margin-bottom: $vr;
  }

  .gap-bottom-author-name {
    font-size: 21px;
    font-family: $font-condensed;
    font-weight: bold;
    line-height: 1;
    margin-bottom: $vr;
  }

  .hs-author-social-links {
    line-height: 1;
  }

  .social {
    margin-right: $vr;
  }

  .more-link {
    display: inline-block;
    margin-bottom: $vr;
  }

  p {
    margin-bottom: $vr;
  }
}

#comments-listing {
  margin-bottom: ($vr * 3);
}

.gap-blog-comments-wrapper {
  background-color: rgba(235, 235, 238, 0.42);
  padding: ($vr * 5) 0;

  h2 {
    font-family: $font-condensed;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 1.5px;
    margin-bottom: 0;
  }

  h2, h4 {
    margin-top: 0;
  }

  .comment-from h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: ($vr * 2);
  }

  .comment-reply-to {
    color: $purple;
    padding: 0 !important;
    background: none;
    font-size: 14px;
    font-family: $font-condensed;
    letter-spacing: 1.6px;
    text-transform: uppercase;

    &:after {
      content: ' \2192';
    }

    &:hover, &:focus {
      color: $black;
    }

    em {
      font-style: normal;
    }
  }

  .comment {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: ($vr * 2);
    margin-bottom: ($vr * 2);
  }

  .comment-date {
    width: 5.6em;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    font-family: $font-condensed;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    color: $dark-gray;
    float: right;
    margin-top: 5px;
  }

  .comment.depth-0 {
    margin-top: 0;
    padding-top: 0;
  }

  input[type=submit] {
    background-color: $black;
  }
}

.widget-type-blog_comments {
  max-width: 627px;
}

a.filter-expand-link {
  color: $dark-gray;
  text-align: right;
  font-family: $font-condensed;
  font-size: 14px;
  letter-spacing: 0.4px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    border: solid 1.5px $dark-gray;
    border-left: none;
    border-bottom: none;
    right: 35px;
    top: 4px;
  }
}

.gap-landingpage-body {
  background-color: $purple;
  color: #fff;
  padding: 85px 0;

  .widget-type-header {
    max-width: 50em;
  }

  h1 {
    font-size: 10vw;
    font-weight: 900;
    line-height: 1.07;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 92px;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.67;
    font-weight: bold;
  }
}

.gap-landingpagecontent {
  font-family: $font-condensed;
  font-size: 19px;
  line-height: 1.58;
  letter-spacing: 0.3px;
  font-weight: normal;

  @media (min-width: 768px) {
    padding-right: 60px;
  }
}

.gap-aside-form {
  background-color: #fff;
  color: $black;
  padding: ($vr * 3) ($vr *2);
  text-align: center;

  &--gray {
    background-color: $light-gray;
  }

  .form-title {
    margin-bottom: 1.333333333em;
    text-transform: uppercase;
    font-family: $font-condensed;
    font-size: 18px;
    letter-spacing: .111111111em;
    margin-top: 0;
  }

  input[type=submit] {
    width: 100%;
    margin-bottom: ($vr * 2);
  }

  .gap-aside-bottom-text {
    font-family: $font-condensed;
    font-size: 16px;
    color: $dark-gray;
  }

  .hs-error-msgs {
    text-align: left;
  }

  input[name=firstname],
  input[name=lastname],
  input[name=email] {
    background-image: url(http://cdn2.hubspot.net/hubfs/2380340/Global_assets/user2x.png);
    background-repeat: no-repeat;
    background-size: 24px;
    padding-left: 50px !important;
    background-position: 15px center;
  }

  input[name=email] {
    background-image: url(http://cdn2.hubspot.net/hubfs/2380340/Global_assets/mail2x.png);
  }
}

.gap-thankyoupage-header {
  background-color: $black;
  color: #fff;
  border-bottom: 1px solid rgba(#fff, .25);
  padding: 140px 0 ($vr * 6) 0;

  h1 {
    font-size: 51px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: ($vr * 4);
    line-height: 1.2;
  }
}

.gap-thankyoupage-body {
  padding: ($vr * 6) 0;

  ul.check li {
    border-bottom-color: $gray;
  }

  .gap-landingpagecontent {
    h2 {
      text-transform: uppercase;
      font-size: 36px;
      letter-spacing: 3px;
      line-height: 1.25;
      font-weight: bold;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.gap-downloadbox {
  display: inline-block;
  border: 2px solid $purple;
  padding: ($vr * 2);
  text-align: center;

  &:hover {
    background-color: $purple;
    text-decoration: none;

    .gap-downloadbox__icon {
      background-image: url(http://cdn2.hubspot.net/hubfs/2380340/Global_assets/downloading-updates-copy2x.png);
    }

    .gap-downloadbox__downloadtext {
      color: #fff;
    }
  }

  &__icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-image: url(http://cdn2.hubspot.net/hubfs/2380340/Global_assets/downloading-updates2x.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
  }

  &__downloadtext {
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    font-family: $font-condensed;
    line-height: 1.43;
    color: $purple;
  }

  &__subtext {
    opacity: 0.29;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.44;
    color: #fff;
  }
}

.gap-share {
  margin-top: ($vr * 4);
  font-weight: bold;

  .social-share-wrapper {
    margin-top: 1.111111111em;
  }
  a {
    display: inline-block;
    font-size: 0;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.gap-flexwrapper {
  @media (min-width: 768px) {
    > .row-fluid-wrapper > .row-fluid {
      display: flex;
    }
  }
}

.gap-genericbody {
  padding: ($vr * 4 + 90px) 0 ($vr * 4) 0;
}
