// colors

$purple: #7902aa;
$black: #100017;
$gray: #d3d3d3;
$dark-gray: #6d6d6d;
$light-gray: #ecebee;
$border-gray: #ebebeb;

// typography
$font-condensed: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-normal: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;


// vertical rythm
$vr: 15px;